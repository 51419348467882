<template>
  <b-container>
    <hr />
    <b-row style="margin-top:10px;">
      <b-col cols="10">
        <b-row style="margin-bottom:10px;">
          <b-col cols="2" style="align-self: center;">
            <span>{{$t('QuestionType')}}</span>
          </b-col>
          <b-col cols="10">
            <v-select transition="" v-model="question.questionType"
                      :reduce="s => s.value"
                      label="name"
                      :clearable="false"
                      :options="questionTypes"
                      @input="typeChange">
              <template #selected-option="option">
                {{$t(option.text) }}
              </template>
              <template #option="option">
                {{$t(option.text) }}
              </template>
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-col>
        </b-row>
        <b-row style="margin-bottom:10px;">
          <b-col cols="2" style="align-self: center;">
            <span>{{$t('Question')}}</span>
          </b-col>
          <b-col cols="10">
            <ValidationProvider :name="$t('Question')" rules="required" v-slot="{ errors }">
              <b-form-textarea v-model="question.question"
                               :class="(errors.length > 0 ? ' is-invalid' : '')"
                               rows="1" required></b-form-textarea>
              <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row v-if="question.questionType=='option' || question.questionType=='option_multiple'" style="justify-content:right;">
          <b-button variant="light" @click="addNewOption()"><font-awesome-icon icon="fa fa-plus" />&nbsp;{{$t('AddOption')}}</b-button>
        </b-row>
        <customer-form-template-question-option v-for="(option, index) in question.customer_FormTemplateQuestionOption"
                                                :key="index"
                                                :option="option"
                                                :option-number="index+1"
                                                 @DeleteOption="DeleteChildOption"/>
      </b-col>
      <b-col cols="2">
        <b-button-group vertical>
          <b-button v-if="canMoveUp" variant="light" @click="MoveUp()"><font-awesome-icon icon="fa fa-chevron-up" /></b-button>
          <b-button v-if="canMoveDown" variant="light" @click="MoveDown()"><font-awesome-icon icon="fa fa-chevron-down" /></b-button>
          <b-dropdown variant="light" id="btnGroupDropdown" :text="$t('Actions')">
            <b-dropdown-item>
              <a class="dropdown-item" @click="CopyQuestion()"><font-awesome-icon icon="fa fa-clipboard" />&nbsp;{{$t('Copy')}}</a>
            </b-dropdown-item>
            <b-dropdown-item>
              <a class="dropdown-item btn-outline-danger" @click="DeleteQuestion()"><font-awesome-icon icon="fa-trash-can" />&nbsp;{{$t('Delete')}}</a>
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import CustomerFormTemplateQuestionOption from '../definitions/CustomerFormTemplateQuestionOption'

  export default {
    name: 'CustomerFormTemplateQuestion',
    components: {
      CustomerFormTemplateQuestionOption
    },
    props: {
      question: Object,
      questionTypes: Array,
      canMoveUp: Boolean,
      canMoveDown: Boolean
    },
    data() {
      return {

      }
    },
    methods: {
      addNewOption() {
        let newOption = {
          id: "",
          displayOrder: this.question.customer_FormTemplateQuestionOption.length + 1,
          optionText: "",
          customerFormTemplateQuestionID: this.question.id,
        };

        this.question.customer_FormTemplateQuestionOption.push(newOption);
      },
      DeleteChildOption: function (deleteOption) {
        if (deleteOption.id && deleteOption.id.length > 0) {
          this.question.customer_FormTemplateQuestionOptionDeleted.push(deleteOption.id);
        }

        this.question.customer_FormTemplateQuestionOption = this.question.customer_FormTemplateQuestionOption.filter((s) => s != deleteOption);
      },
      typeChange() {
        this.question.customer_FormTemplateQuestionOption = [];

        if (this.question.questionType == "option" || this.question.questionType == "option_multiple") {
          this.addNewOption();
        }
      },
      MoveUp: function () {
        this.$emit("MoveUp", this.question, '+');
      },
      MoveDown: function () {
        this.$emit("MoveDown", this.question , '-');
      },
      CopyQuestion: function () {
        let copiedQuestion = JSON.parse(JSON.stringify(this.question));
        copiedQuestion.id = null;

        copiedQuestion.customer_FormTemplateQuestionOption.forEach(function (option) {
          option.id = null;
          option.customerFormTemplateQuestionID = null;
        });

        this.$emit("CopyQuestion", copiedQuestion);
      },
      DeleteQuestion: function () {
        this.$emit("DeleteQuestion", this.question);
      }
    },
    mounted() {

    }
  }
</script>
