import { render, staticRenderFns } from "./CustomerFormTemplateQuestion.vue?vue&type=template&id=77f3610d&"
import script from "./CustomerFormTemplateQuestion.vue?vue&type=script&lang=js&"
export * from "./CustomerFormTemplateQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports